export const devocajasTypes = {
	devoCajasInicializar: 'devoCajasInicializar',
	devoCajasToggle: 'devoCajasToggle',
	devoCajasModificar: 'devoModificar',
};

// ACTIONS

export const devoCajasInicializar = () => {
	return (dispatch) => {
		dispatch({ type: devocajasTypes.devoCajasInicializar });
	};
}

export const devoCajasToggle = (cierreCaja) => {
	return (dispatch) => {
		dispatch({ type: devocajasTypes.devoCajasToggle, cierreCaja });
	};
}

export const devoCajasModificar = (caja, name, value) => {
	return (dispatch) => {
		dispatch({ type: devocajasTypes.devoCajasModificar, caja, name, value });
	};
}

const actions = {
	devoCajasInicializar,
	devoCajasToggle,
	devoCajasModificar
}

export default actions;

// REDUCERS

export const generarDevolucionescajasReducer = (state = [], action) => {
	switch (action.type) {
		case devocajasTypes.devoCajasInicializar:
			return [];
		case devocajasTypes.devoCajasToggle:
			var existeEnArray = state.filter((x) => x.idCaja === action.cierreCaja.idCaja).length !== 0;
			if(existeEnArray){
				return state.map((devoCaja) => {
					if(devoCaja.idCaja === action.cierreCaja.idCaja){
						return { ...devoCaja, estaAgregada: !devoCaja.estaAgregada }
					}
					return devoCaja;
				});
			}
			return state.concat({ ...action.cierreCaja, estaAgregada: true });
		case devocajasTypes.devoCajasModificar:
			// eslint-disable-next-line no-redeclare
			var existeEnArray = state.filter((x) => x.idCaja  === action.cierreCaja.idCaja).length !== 0;
			if(existeEnArray){
				return state.map((devoCaja) => {
					if(devoCaja.idCaja === action.cierreCaja.idCaja){
						return { ...devoCaja, [action.name]: action.value }
					}
					return devoCaja;
				});
			}

			return state.concat({
				...action.cajas,
				estaAgregada: false,
				[action.name]: action.value
			});
		default:
			return state;
	}
};
