export const generarcajasTypes = {
	cajasInicializar: 'cajasInicializar',
	cajasToggle: 'cajasToggle',
	cajasModificar: 'cajasModificar',
};

// ACTIONS

export const cajasInicializar = () => {
	return (dispatch) => {
		dispatch({ type: generarcajasTypes.cajasInicializar });
	};
}

export const cajaToggle = (caja) => {
	return (dispatch) => {
		dispatch({ type: generarcajasTypes.cajasToggle, caja });
	};
}

export const cajaModificar = (caja, name, value) => {
	return (dispatch) => {
		dispatch({ type: generarcajasTypes.cajasModificar, caja, name, value });
	};
}

const actions = {
	cajasInicializar,
	cajaToggle,
	cajaModificar
}

export default actions;

// REDUCERS

export const pedirCajasReducer = (state = [], action) => {
	switch (action.type) {
		case generarcajasTypes.cajasInicializar:
			return [];
		case generarcajasTypes.cajasToggle:
			var existeEnArray = state.filter((x) => x.idCaja === action.caja.idCaja).length !== 0;
			if(existeEnArray){
				return state.map((cajas) => {
					if(cajas.idCaja === action.caja.idCaja){
						return { ...cajas, estaAgregada: !cajas.estaAgregada }
					}
					return cajas;
				});
			}
			return state.concat({ ...action.caja, estaAgregada: true });
		case generarcajasTypes.cajasModificar:
			// eslint-disable-next-line no-redeclare
			var existeEnArray = state.filter((x) => x.idCaja  === action.caja.idCaja).length !== 0;
			if(existeEnArray){
				return state.map((cajas) => {
					if(cajas.idCaja === action.caja.idCaja){
						return { ...cajas, [action.name]: action.value }
					}
					return cajas;
				});
			}

			return state.concat({
				...action.cajas,
				estaAgregada: false,
				[action.name]: action.value
			});
		default:
			return state;
	}
};
